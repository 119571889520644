import request from '@/utils/request';

// 列表
export function List(params) {
  return request({
    url: '/admin/form/report/page',
    method: 'GET',
    params
  });
}

// 详情
export function Detail(params) {
  return request({
    url: '/admin/form/report/detail',
    method: 'GET',
    params
  });
}
