<template>

  <div class="page-cu-container">
    <div class="page-cu-main">
      <a-row type="flex" class="page-cu-row">
        <a-col :span="24" class="page-cu-left">
          <div class="page-cu-left-head">
<!--            <a-form layout="inline">-->
<!--              <a-form-model-item>-->
<!--                <a-input v-model="page.key"  placeholder="请输入关键字"></a-input>-->
<!--              </a-form-model-item>-->
<!--              <a-form-model-item>-->
<!--                <a-button type="primary" @click="search">搜索</a-button>-->
<!--              </a-form-model-item>-->
<!--            </a-form>-->
          </div>
          <div class="page-cu-left-table">
            <vuescroll>
              <a-table :columns="columns" :data-source="list" rowKey="id" bordered :pagination="false">
                <template slot="is_apply" slot-scope="text, record">
                    <span v-if="text == '1'">未审核</span>
                    <span v-if="text == '2'">审核通过</span>
                    <span v-if="text == '3'">审核拒绝</span>
                    <span v-if="text == '4'">申请编辑中</span>
                    <span v-if="text == '5'">可编辑</span>
                    <span v-if="text == '6'">申请删除中</span>
                </template>
                <span slot="action" slot-scope="text, record">
                  <a-button type="eye" @click="openDetailDialog(record)">详情</a-button>
                </span>

              </a-table>
              <div class="page-cu-left-page">
                <a-pagination
                  v-model="page.start"
                  :page-size.sync="page.pageSize"
                  :total="page.totalRow"
                  @change="changePage"
                />
              </div>
            </vuescroll>
          </div>
        </a-col>
      </a-row>
    </div>
    <a-modal title="客户详情" :width="800" :destroyOnClose="true" :confirmLoading="$store.state.requestLoading"
             :visible="actionVisible" :footer="null" @cancel="cancel">
      <table class="cu-table" id="print-table">
        <tr><td class="field-left">所属公司</td><td>{{record.company_name}}</td></tr>
        <tr><td class="field-left">所属部门</td><td>{{record.department}}</td></tr>
        <tr><td class="field-left">所属销售</td><td>{{record.admin_name}}</td></tr>
        <tr><td class="field-left">联系人</td><td>{{record.name}}</td></tr>
        <tr><td class="field-left">联系电话</td><td>{{record.mobile}}</td></tr>
        <tr><td class="field-left">职位</td><td>{{record.position}}</td></tr>
        <tr><td class="field-left">客户状态</td><td>{{record.status}}</td></tr>
        <tr><td class="field-left">审核状态</td><td><span v-if="record.is_apply == '1'">未审核</span>
          <span v-if="record.is_apply == '2'">审核通过</span>
          <span v-if="record.is_apply == '3'">审核拒绝</span>
          <span v-if="record.is_apply == '4'">申请编辑中</span>
          <span v-if="record.is_apply == '5'">可编辑</span>
          <span v-if="record.is_apply == '6'">申请删除中</span></td></tr>
        <tr><td class="field-left">备注</td><td>{{record.content}}</td></tr>
        <tr><td class="field-left">创建时间</td><td>{{record.create_time}}</td></tr>
      </table>
      <div style="margin: 20px;display: flex;justify-content: center"><a-button v-print="'#print-table'">打印</a-button></div>
    </a-modal>
  </div>
</template>

<script>
  import * as Api from './api';
  import axios from 'axios';

  export default {
    name: "index",
    data() {
      return {
        formModel: {
          title: '',
          subtitle: '',
          img_url: '',
          content: '',
          type: '',
          sort: ''
        },
        formModelRules: {
          title: [{required: true, message: '请输入标题', trigger: 'change'}],
          subtitle: [{required: true, message: '请输入副标题', trigger: 'change'}],
          img_url: [{required: true, message: '请上传图片', trigger: 'change'}],
          content: [{required: true, message: '请输入内容', trigger: 'change'}],
          type: [{required: true, message: '请输入分类名称', trigger: 'change'}],
          sort: [{required: true, message: '请输入序号', trigger: 'change'}],
        },
        columns: [
          {
            title: '公司名称',
            dataIndex: 'company_name',
          },
          {
            title: '项目名称',
            dataIndex: 'product_name',
          },
          {
            title: '负责人',
            dataIndex: 'principal'
          },
          {
            title: '联系电话',
            dataIndex: 'principal_mobile'
          },
          {
            title: '机器型号',
            dataIndex: 'machine_type'
          },
          {
            title: '品牌',
            dataIndex: 'brand'
          },
          {
            title: '报备人',
            dataIndex: 'admin_name'
          },
          {
            title: '招标时间',
            dataIndex: 'bidding_time'
          },
          {
            title: '报备时间',
            dataIndex: 'create_time',
            width:200
          },
          {
            title: '操作',
            width: 150,
            scopedSlots: {customRender: 'action'},
          },
        ],
        page: {
          start: 1,
          totalRow: 0,
          pageSize: 0,
          limit: 20,
          key: ''
        },
        list: [],
        fileList: [],
        actionVisible: false,
        record: {}
      }
    },
    mounted() {
      this.getList();

    },
    methods: {
      /**
       * 打开表格详情
       * @author wheat
       * */
      openDetailDialog(record) {
        this.record = record;
        this.actionVisible = true;
      },
      /**
       * 搜索
       * @author wheat
       * */
      search() {
        this.getList();
      },
      /**
       * 关闭穿口
       * @author wheat
       * */
      cancel() {
        this.actionVisible = false;
      },
      /**
       * 获取列表
       * @author wheat
       * */
      async getList() {
        let res = await Api.List({
          start: this.page.start,
          limit: this.page.limit,
        })
        this.page.totalRow = res.page.totalRow;
        this.page.pageSize = res.page.pageSize;
        this.list = res.page.list;
      },
      /**
       * 分页切换
       * @author wheat
       * */
      changePage(currentPage) {
        this.page.start = currentPage;
        this.getList();
      }
    },
  }
</script>

<style scoped>
.cu-table
{
  border-collapse:collapse;
  width: 100%;
}

.cu-table,th, td
{
  border: 1px solid #000000;
  padding: 10px;
}
.field-left {
  width: 100px;
}
</style>
